
.loading-page {
  background-color: #000000;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.loading-logo {
  height: auto;
  width: 200px;
}

.header-logo {
  height: 80px;
  width: auto;
}

.list-main-text {
  font-weight: "bold";
  font-family: Avenir Book;
  font-size: 18px
}

.drawer-logo {
  height: 40px;
}

.list-item {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #dddddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.list-item:hover {
  cursor: pointer;
  background-color: #d2d2d2;
  border-bottom: solid 1px #666;
}

.landing-page {
  background-color: #000000;
  height: 100%;
  width: 100vw;
}

.h1 {
  font-family: Avenir Book;
  color: white;
  text-align: center;
  font-size: 24px;
}

.card-heading {
  font-family: Avenir Book;
  color: #000000;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.card-subheading {
  font-family: Avenir Book;
  color: #000000;
  text-align: center;
  font-size: 18px;
}

.black-button {
  background-color: #000000;
  align-self: center;
  font-family: Avenir Book;
  font-size: 14px;
  width: 220px;
  height: 40px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  border-width: 0px;
  color: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 10px #666;
}

.text-only-button {
  background-color: #ffffff;
  align-self: center;
  font-family: Avenir Book;
  font-size: 16px;
  width: 260px;
  height: 40px;
  border-radius: 20px;
  border-width: 0px;
  color: #334856;
  cursor: pointer;
  text-decoration: underline;
}

.card-shadow {
  box-shadow: 0 0 10px #666;
  background-color: white;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.workout-card {
  margin-top: 15px;
  box-shadow: 0 0 10px #666;
  background-color: white;
  width: 100%;
  border-radius: 15px;
}

.error-text {
  font-family: Avenir Book;
  color: #ff0000;
  font-size: 14;
}

.menu-font {
  font-family: Avenir Book;
  color: #ffffff;
  font-size: 14;
}

.page-header {
  font-family: Avenir Book;
  color: #ffffff;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

.white-link {
  font-family: Avenir Book;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.onboarding-list-item {
  display: flex;
  flex-direction: row;
  height: 60px;
  margin: 10px;
  border-bottom: solid 1px #d2d2d2;
  align-items: center;
}

.weekday-name {
  font-family: Avenir Book;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}


.workout-content {
  font-family: Avenir Book;
  text-align: center;
  font-size: 14px;
  color: #000000;
}  


.weekday-view {
  width: 180px;
  border-right: solid 1px;
  height: 600px;
  overflow: scroll;
  text-align: center;
}

.weekday-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #fafafa;
  margin: 4px;
  color: #334856;
  border-radius: 4px;
  border: solid 1px #334856;
  box-shadow: 0 0 8px #666666;
  padding: 4px;
  cursor: pointer;
  margin-bottom: 20px;

}

.weekday-button:hover {
  background-color: #334856;
  color: #ffffff;
  border: solid 1px #ffffff;
}

.cal-add-workout-button-text {
  font-family: Avenir Book;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #666;
}

.cal-add-workout-button-text:hover {
  color: #ffffff;
}


.pagination-button {
  height: 50px;
  width: 50px;
  background-color: #d0d0d0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #334856;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 0 8px #666666;
}

.calendar-workout-card {
  background-color: #fafafa;
  margin: 4px;
  color: #334856;
  border-radius: 4px;
  border: solid 1px #334856;
  box-shadow: 0 0 8px #666666;
  padding: 4px;
  cursor: pointer;
}

.calendar-workout-card:hover {
  background-color: #d0d0d0;
}

/* Material UI Overrides */

.MuiFormLabel-root.Mui-focused {
  color: #000000 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #000000 !important;
}

button:focus {
  outline: none !important;
}

.normal-text {
  font-family: Avenir Book;
  font-size: 14px;
  color: #666;
}

.whiteboard-content {
  font-family: Avenir Book;
}

.card-content-text {
  font-family: Avenir Book;
  font-size: 16px;
  color: #324755;
  padding-top: 5px;
}

.book-service-service-name {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold,
}

.book-service-service-name {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}


.checked-outer {
  width: 18px;
  height: 18px;
  background-color: #000;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.checked-inner {
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 6px;
  border-width: 2px;
  border-color: #ffffff;
}


.unchecked-inner {
  width: 12px;
  height: 12px;
  background-color: #ffffff;
   border-radius: 6px;
   border-width: 2px;
   border-color: #ffffff;
}

.service-list-item:hover {
  background-color: #dddddd;
}

.family-list-item {
  display: flex;
  flex-direction: row;
  width: 350px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: solid #ddd 1px;
  padding: 5px;
  border-radius: 5px;
}

.family-list-item:hover {
cursor: pointer;
background-color: #dddddd;
}

.kidszone-list-item {
  display: flex;
  flex-direction: row;
  width: 350px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: solid #ddd 1px;
}

.kidszone-selector {
   width: 72px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #ffffff;
}